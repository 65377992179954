@use "@angular/material" as mat;
@import "/src/@fuse/scss/fuse";

login {
	#login {
		width: 100%;
		background-color: #e7e7e8;
		// background: url('/assets/images/backgrounds/dark-material-bg.jpg') no-repeat;
		// background-size: cover;

		#login-form-wrapper {
			flex: 0.6 0 auto;
			padding: 32px;

			@include media-breakpoint("xs") {
				padding: 16px;
			}

			#login-form,
			#remember-form {
				width: 480px;
				max-width: 480px;
				padding: 40px 50px;
				text-align: center;
				border-top-style: solid;
				border-top-width: 4px;
				border-radius: 3px;
				@include mat.elevation(16);

				@include media-breakpoint("xs") {
					padding: 24px;
					width: 100%;
				}

				.logo {
					width: 60%;
					margin: 24px auto;
					margin-top: 0;
				}

				.title {
					font-size: 20px;
					margin: 16px 0 32px 0;
				}

				form {
					width: 100%;
					text-align: left;

					mat-form-field {
						width: 100%;
					}
					/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
					mat-checkbox {
						margin: 0;
					}

					.remember-forgot-password {
						font-size: 14px;
						margin-top: 6px;

						.remember-me {
							margin-bottom: 16px;
						}

						.forgot-password {
							font-size: 14px;
							font-weight: 600;
							margin-bottom: 16px;
						}
					}

					.submit-button {
						width: 100%;
						margin: 16px auto;
						display: block;
						font-size: 16px;
						height: 44px;

						@include media-breakpoint("xs") {
							width: 90%;
						}
					}
				}

				.register {
					margin: 32px auto 24px auto;
					font-weight: 600;

					.text {
						margin-right: 8px;
					}
				}

				.separator {
					font-size: 15px;
					font-weight: 600;
					margin: 10px auto;
					position: relative;
					overflow: hidden;
					width: 90%;

					.text {
						display: inline-flex;
						position: relative;
						padding: 0 8px;
						z-index: 9999;

						&:before,
						&:after {
							content: "";
							display: block;
							width: 120px;
							position: absolute;
							top: 10px;
							border-top: 1px solid;
						}

						&:before {
							right: 100%;
						}

						&:after {
							left: 100%;
						}
					}
				}

				button {
					&.google,
					&.facebook,
					&.outlook {
						width: 192px;
						text-transform: none;
						color: #ffffff !important;
						font-size: 16px;
						height: 44px;
					}

					@include media-breakpoint("xs") {
						width: 80%;
					}

					&.google {
						background-color: #d73d32 !important;
						margin-bottom: 8px;
					}

					&.facebook {
						background-color: rgb(63, 92, 154) !important;
					}

					&.outlook {
						background-color: #0078d4 !important;
						margin-bottom: 8px;
					}
				}
			}
		}
	}

	a {
		&:hover {
			cursor: pointer;
		}
	}
}
